import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { authClient } from '../services/authClient';
import { networkManager } from '../services/NetworkManager';

const stripePromise = loadStripe('pk_test_51PaErsCaxP2A8bXCFplmg3cPl8Mc5ftQlwPwkC4lBeRe0ZkfhHOSf3LqGy5DZp1WuXfgxU5QuRnX0I07f57riagc00HHRvA5i4');

interface Plan {
  name: string;
  price: number;
  maxVideos: number;
  maxDuration: number;
  features: string[];
  stripeId: string;
}

const Subscription: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(authClient.getCurrentUser());
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const unsubscribe = authClient.onAuthStateChanged((user) => {
      setUser(user);
    });

    fetchSubscriptions();

    return () => unsubscribe();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const subscriptions = await networkManager.get('/subscriptions');
      setPlans(subscriptions);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  const handleSubscribe = async (priceId: string) => {
    if (!user) {
      navigate('/login');
      return;
    }

    const stripe = await stripePromise;
    const token = await authClient.getToken();

    if (!stripe || !token) {
      console.error('Stripe not loaded or user not authenticated');
      return;
    }

    try {
      const response = await networkManager.post('/create-checkout-session', {
        priceId: priceId,
        userId: user.uid
      });

      const result = await stripe.redirectToCheckout({
        sessionId: response.id
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Choose Your TimeCapsule Plan
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Select the plan that best fits your needs
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
          {plans.map((plan) => (
            <div key={plan.name} className="bg-white rounded-lg shadow-lg divide-y divide-gray-200">
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-900">{plan.name}</h2>
                <p className="mt-4 text-gray-500">
                  {`${plan.maxVideos} videos • ${plan.maxDuration} seconds each • ${plan.features.join(' • ')}`}
                </p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">${plan.price}</span>
                  <span className="text-base font-medium text-gray-500">/year</span>
                </p>
                <button
                  onClick={() => handleSubscribe(plan.stripeId)}
                  className="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700"
                >
                  Subscribe
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Subscription;