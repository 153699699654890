import React, { useState, useEffect }  from 'react';
import { motion } from 'framer-motion';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate } from 'react-router-dom';
import { authClient } from '../services/authClient';
import { networkManager } from '../services/NetworkManager';

interface Subscription {
  name: string;
  price: number;
  maxVideos: number;
  maxDuration: number;
  features: string[];
  stripeId: string;
}

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const user = authClient.getCurrentUser();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await networkManager.get('/subscriptions');
      setSubscriptions(response);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await authClient.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <header className="bg-white shadow">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
          <div className="w-full py-6 flex items-center justify-between border-b border-indigo-500 lg:border-none">
            <div className="flex items-center">
              <Link to="/">
                <span className="sr-only">TimeCapsule</span>
                <img
                  className="h-10 w-auto sm:h-12 md:h-16 lg:h-20 object-contain"
                  src="/logo.svg"
                  alt="TimeCapsule Logo"
                />
              </Link>
              <div className="hidden ml-10 space-x-8 lg:block">
                <a href="#how-it-works" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  How it Works
                </a>
                <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  Pricing
                </a>
                <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  About Us
                </a>
              </div>
            </div>
            <div className="ml-10 space-x-4">
              {user ? (
                <>
                  <Link
                    to="/dashboard"
                    className="inline-block bg-indigo-500 py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"
                  >
                    Dashboard
                  </Link>
                  <button
                    onClick={handleSignOut}
                    className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50"
                  >
                    Sign out
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="inline-block bg-indigo-500 py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"
                  >
                    Sign in
                  </Link>
                  <Link
                    to="/signup"
                    className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50"
                  >
                    Sign up
                  </Link>
                </>
              )}
            </div>
          </div>
        </nav>
      </header>

      <main>
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <motion.h1
              className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <span className="block">Your Final Messages,</span>
              <span className="block text-indigo-600">Delivered with Care</span>
            </motion.h1>
            <motion.p
                className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                TimeCapsule securely stores your video messages, delivering them to loved ones after you're gone. Record your legacy, set delivery conditions, and ensure your final thoughts are shared as intended.
              </motion.p>
            <motion.div
              className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <div className="rounded-md shadow">
                <Link
                  to={user ? "/dashboard" : "/signup"}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                  {user ? "Go to Dashboard" : "Start Your TimeCapsule"}
                </Link>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="#how-it-works"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Learn How It Works
                </a>
              </div>
            </motion.div>
          </div>
        </div>

        <div id="how-it-works" className="bg-gray-100">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">How TimeCapsule Works</h2>
              <p className="mt-4 text-lg text-gray-500">
                Ensure your final messages reach your loved ones with our secure, thoughtful process.
              </p>
            </div>
            <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:gap-y-12 lg:gap-x-8">
              {[
                {
                  name: 'Record Your Messages',
                  description: 'Create personal video messages for your loved ones to be delivered after you\'re gone.',
                },
                {
                  name: 'Regular Pulse Checks',
                  description: 'Receive periodic check-ins to confirm you\'re still with us. Simply respond to keep your messages secure.',
                },
                {
                  name: 'Trusted Verifiers',
                  description: 'Designate two trusted individuals who must confirm your passing before messages are released.',
                },
                {
                  name: 'Secure Storage',
                  description: 'Your messages are encrypted and securely stored until it\'s time for delivery.',
                },
                {
                  name: 'Customized Delivery',
                  description: 'Set specific conditions for when and how each message should be delivered.',
                },
                {
                  name: 'Peace of Mind',
                  description: 'Rest easy knowing your final thoughts and wishes will be shared exactly as you intend.',
                },
              ].map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <ChevronRightIcon
                      className="absolute h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div id="pricing" className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Choose Your TimeCapsule Plan
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Select the plan that best fits your needs
            </p>
          </div>

          <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
            {subscriptions.map((plan) => (
              <div key={plan.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                <div className="p-6 text-center">
                  <h2 className="text-2xl font-semibold text-gray-900">{plan.name}</h2>
                  <p className="mt-4 text-gray-500">{plan.maxVideos} videos • {plan.maxDuration} seconds each</p>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">${plan.price}</span>
                    <span className="text-base font-medium text-gray-500">/month</span>
                  </p>
                  <Link
                    to={user ? "/subscription" : "/signup"}
                    className="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700"
                  >
                    Choose Plan
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      </main>


      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            {/*['Facebook', 'Twitter', 'GitHub'].map((item) => (
              <a key={item} href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item}</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
              </a>
            ))*/}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">
              &copy; 2023 TimeCapsule, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;