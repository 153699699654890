import React, { useState, useEffect, useCallback } from 'react';

interface TimerProps {
  mode: 'countdown' | 'recording' | 'idle';
  maxDuration: number;
  onCountdownEnd: () => void;
  onMaxDurationReached: () => void;
}

const Timer: React.FC<TimerProps> = ({ mode, maxDuration, onCountdownEnd, onMaxDurationReached }) => {
  const [time, setTime] = useState(mode === 'countdown' ? 5 : 0);

  const tick = useCallback(() => {
    setTime(prevTime => {
      if (mode === 'countdown') {
        if (prevTime <= 1) {
          onCountdownEnd();
          return 0;
        }
        return prevTime - 1;
      } else if (mode === 'recording') {
        if (prevTime >= maxDuration - 1) {
          onMaxDurationReached();
          return maxDuration;
        }
        return prevTime + 1;
      }
      return prevTime;
    });
  }, [mode, maxDuration, onCountdownEnd, onMaxDurationReached]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (mode === 'countdown' || mode === 'recording') {
      intervalId = setInterval(tick, 1000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [mode, tick]);

  useEffect(() => {
    if (mode === 'countdown') {
      setTime(5);
    } else if (mode === 'recording') {
      setTime(0);
    }
  }, [mode]);

  if (mode === 'idle') return null;

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="absolute bottom-4 left-4 right-4">
      <div className="bg-black bg-opacity-70 text-white px-4 py-2 rounded-lg flex justify-center items-center">
        {mode === 'countdown' ? (
          <div className="text-6xl font-bold">{time}</div>
        ) : (
          <div className="w-full flex justify-between items-center">
            <div className="text-3xl font-semibold">{formatTime(time)}</div>
            <div className="text-lg">/ {formatTime(maxDuration)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Timer;
