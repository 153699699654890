import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import VideoManagement from './VideoManagement';
import EmergencyContacts from './EmergencyContacts';
import Settings from './Settings';
import AccountStatusVerification from './AccountStatusVerification';
import { networkManager } from '../services/NetworkManager';

interface AccountStatus {
  isActive: boolean;
  nextHealthCheck: string | null;
  subscription: {
    type: string;
  } | null;
}

const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('videos');
  const [accountStatus, setAccountStatus] = useState<AccountStatus>({
    isActive: false,
    nextHealthCheck: null,
    subscription: null,
  });
  const [loading, setLoading] = useState(true);

  const tabs = [
    { id: 'videos', label: 'Videos' },
    { id: 'emergency', label: 'Emergency Contacts' },
    { id: 'settings', label: 'Settings' },
  ];

  useEffect(() => {
    checkAccountStatus();
  }, [activeTab]);

  const checkAccountStatus = async () => {
    try {
      setLoading(true);
      const status = await networkManager.get('/account-status');
      setAccountStatus(status);
    } catch (error) {
      console.error('Error checking account status:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="min-h-screen bg-gray-100 flex items-center justify-center">Loading...</div>;
  }

  const hasValidSubscription = accountStatus.subscription && Object.keys(accountStatus.subscription).length > 0;

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="h-8 w-auto"
                  src="/logo.svg"
                  alt="TimeCapsule"
                />
              </div>
              <div className="hidden md:ml-6 md:flex md:space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`${
                      activeTab === tab.id
                        ? 'border-indigo-500 text-gray-900'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                    } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="md:hidden flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    {tabs.find(tab => tab.id === activeTab)?.label}
                    <svg className="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </Menu.Button>
                </div>
                <Transition
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {tabs.map((tab) => (
                        <Menu.Item key={tab.id}>
                          {({ active }) => (
                            <button
                              onClick={() => setActiveTab(tab.id)}
                              className={`${
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                              } block px-4 py-2 text-sm w-full text-left`}
                            >
                              {tab.label}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="hidden md:ml-6 md:flex md:items-center">
              <button
                type="button"
                className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">View notifications</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <AccountStatusVerification onTabChange={activeTab} />

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {activeTab === 'videos' && (
            hasValidSubscription ? (
              <VideoManagement />
            ) : (
              <div className="bg-white shadow sm:rounded-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">Video Management</h2>
                <div className="space-y-4 opacity-50 pointer-events-none">
                  <div className="flex items-center justify-between">
                    <div className="flex-1">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="mt-2 h-4 bg-gray-200 rounded w-1/2"></div>
                    </div>
                    <div className="w-24 h-10 bg-gray-200 rounded"></div>
                  </div>
                  <div className="border-t border-gray-200 pt-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {[1, 2, 3].map((i) => (
                        <div key={i} className="space-y-2">
                          <div className="h-32 bg-gray-200 rounded"></div>
                          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-6 text-center text-sm text-gray-500">
                 <b> Please subscribe to access video management features. </b>
                </div>
              </div>
            )
          )}
          {activeTab === 'emergency' && <EmergencyContacts />}
          {activeTab === 'settings' && <Settings />}
        </motion.div>
      </main>
    </div>
  );
};

export default Dashboard;