// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-FS9li0kKL43ug1ku_MBgekrDIRSBZls",
  authDomain: "timecapsules-d1ba4.firebaseapp.com",
  projectId: "timecapsules-d1ba4",
  storageBucket: "timecapsules-d1ba4.appspot.com",
  messagingSenderId: "473687861007",
  appId: "1:473687861007:web:7525deb1c46febc6eb407a",
  measurementId: "G-X6TWSSDX6M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);