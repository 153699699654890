// src/components/VideoManagement/VideoItem.tsx

import React from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { Video } from './types';

interface VideoItemProps {
  video: Video;
  onDelete: (id: string, url: string) => void;
  onUpdateContact: (id: string, updatedContact: { email: string, phone: string }) => void;
}

const VideoItem: React.FC<VideoItemProps> = ({ video, onDelete, onUpdateContact }) => {
  return (
    <li className="col-span-1 bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6 space-y-4">
        <div className="flex justify-between items-start">
          <h4 className="text-lg font-medium text-gray-900">{video.title}</h4>
          <div className="flex space-x-2">
            <button 
              onClick={() => onDelete(video.id, video.url)} 
              className="text-red-600 hover:text-red-800 transition-colors"
            >
              <FaTrash />
            </button>
            <button 
              onClick={() => {/* Implement edit functionality */}} 
              className="text-blue-600 hover:text-blue-800 transition-colors"
            >
              <FaEdit />
            </button>
          </div>
        </div>
        <video src={video.url} controls className="w-full rounded-md shadow-sm" />
        <div className="text-sm text-gray-600">Duration: {video.duration ? video.duration.toFixed(2) : 0} seconds</div>
        <div className="space-y-2">
          <p className="text-sm text-gray-600">Contact: {video.contact.fullName}</p>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={video.contact.email}
              onChange={(e) => onUpdateContact(video.id, { email: e.target.value, phone: video.contact.phone })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Phone</label>
            <PhoneInput
              country={video.contact.countryCode}
              value={video.contact.phone}
              onChange={(phoneNumber: string, country: CountryData) => {
                onUpdateContact(video.id, { email: video.contact.email, phone: phoneNumber });
              }}
              inputClass="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              containerClass="mt-1"
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default VideoItem;
