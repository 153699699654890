import React, { useState, useEffect } from 'react';
import { networkManager } from '../services/NetworkManager';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51PaErsCaxP2A8bXCFplmg3cPl8Mc5ftQlwPwkC4lBeRe0ZkfhHOSf3LqGy5DZp1WuXfgxU5QuRnX0I07f57riagc00HHRvA5i4');

interface Subscription {
  name: string;
  maxVideos: number;
  maxDuration: number;
  price: number;
  stripeId: string;
}

const ManageSubscription: React.FC = () => {
  const [currentSubscription, setCurrentSubscription] = useState<Subscription | null>(null);
  const [availableSubscriptions, setAvailableSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const fetchSubscriptionData = async () => {
    try {
      setLoading(true);
      const settings = await networkManager.get('/settings');
      const subscriptions = await networkManager.get('/subscriptions');
      
      setCurrentSubscription(settings.subscriptionPlan);
      setAvailableSubscriptions(subscriptions.filter((sub: Subscription) => sub.stripeId !== settings.subscriptionPlan?.stripeId));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching subscription data:', error);
      setError('Failed to load subscription information. Please try again.');
      setLoading(false);
    }
  };

  const handleChangeSubscription = async (stripeId: string) => {
    try {
      setLoading(true);
      const response = await networkManager.post('/create-checkout-session', { priceId: stripeId });
      const stripe = await stripePromise;
      if (stripe) {
        const result = await stripe.redirectToCheckout({
          sessionId: response.id
        });
        if (result.error) {
          throw new Error(result.error.message);
        }
      }
    } catch (error) {
      console.error('Error changing subscription:', error);
      setError('Failed to change subscription. Please try again.');
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!currentSubscription) return;
  
    if (window.confirm('Are you sure you want to cancel your subscription? This action cannot be undone.')) {
      try {
        setLoading(true);
        await networkManager.post('/cancel-subscription',{});
        alert('Your subscription has been cancelled.');
        await fetchSubscriptionData(); // Refresh the subscription data
        setLoading(false);
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        setError('Failed to cancel subscription. Please try again.');
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Manage Subscription</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">View and change your subscription plan</p>
      </div>
      {error && (
        <div className="px-4 py-3 bg-red-100 text-red-700 border-l-4 border-red-500">
          {error}
        </div>
      )}
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Current Plan</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {currentSubscription ? (
                <div>
                  <p>{currentSubscription.name}</p>
                  <p>{currentSubscription.maxVideos} videos, {currentSubscription.maxDuration} seconds each</p>
                  
                  <button
                    onClick={handleCancelSubscription}
                    className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : 'Cancel Subscription'}
                  </button>
                </div>
              ) : (
                <p>No active subscription</p>
              )}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Available Plans</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {availableSubscriptions.map((sub) => (
                <div key={sub.stripeId} className="mb-4 p-4 border rounded">
                  <h4 className="font-medium">{sub.name}</h4>
                  <p>{sub.maxVideos} videos, {sub.maxDuration} seconds each</p>
                  <p>${sub.price}/year</p>
                  <button
                    onClick={() => handleChangeSubscription(sub.stripeId)}
                    className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading}
                  >
                    {loading ? 'Processing...' : 'Switch to this plan'}
                  </button>
                </div>
              ))}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default ManageSubscription;