import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaUpload } from 'react-icons/fa';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RecordingMode } from './types';

interface VideoUploaderProps {
  onFileSelect: (file: File) => void;
  onUpload: (title: string, contactInfo: any) => void;
  setMode: (mode: RecordingMode) => void;
  mode: RecordingMode;
}

const VideoUploader: React.FC<VideoUploaderProps> = ({ onFileSelect, onUpload, setMode, mode }) => {
  const [videoTitle, setVideoTitle] = useState('');
  const [contactInfo, setContactInfo] = useState({
    fullName: '',
    email: '',
    phone: '',
    countryCode: '',
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith('video/')) {
      onFileSelect(selectedFile);
      setMode('upload');
    } else {
      alert('Please select a valid video file.');
    }
  };

  return (
    <div className="w-full">
      {mode === 'choose' && (
        <motion.label
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="w-full flex items-center justify-center p-4 bg-blue-500 text-white rounded-lg cursor-pointer transition-colors hover:bg-blue-600 h-14"
        >
          <FaUpload className="mr-2" />
          <span className="font-medium">Upload Video</span>
          <input type="file" accept="video/*" onChange={handleFileChange} className="hidden" />
        </motion.label>
      )}

      {mode === 'upload' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="p-4 max-w-md mx-auto"
        >
          <input
            type="text"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
            placeholder="Enter video title"
            className="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            value={contactInfo.fullName}
            onChange={(e) => setContactInfo({ ...contactInfo, fullName: e.target.value })}
            placeholder="Contact Full Name"
            className="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="email"
            value={contactInfo.email}
            onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
            placeholder="Contact Email"
            className="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <PhoneInput
            country={'us'}
            value={contactInfo.phone}
            onChange={(phone: string, country: CountryData) => {
              setContactInfo({
                ...contactInfo,
                phone: phone,
                countryCode: country.countryCode,
              });
            }}
            inputClass="w-full px-3 py-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            containerClass="mb-3"
          />
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => setMode('choose')}
              className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300 h-12 w-40"
            >
              Cancel
            </button>
            <button
              onClick={() => onUpload(videoTitle, contactInfo)}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 h-12 w-40"
            >
              Upload Video
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default VideoUploader;