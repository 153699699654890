import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { networkManager } from '../services/NetworkManager';
import ManageSubscription from './ManageSubscription';

const Settings: React.FC = () => {
  const [healthCheckFrequency, setHealthCheckFrequency] = useState('weekly');
  const [notificationMethod, setNotificationMethod] = useState('email');
  const [healthCheckEmail, setHealthCheckEmail] = useState('');
  const [healthCheckPhone, setHealthCheckPhone] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const settings = await networkManager.get('/settings');
      setHealthCheckFrequency(settings.healthCheckFrequency);
      setNotificationMethod(settings.notificationMethod);
      setHealthCheckEmail(settings.healthCheckEmail);
      setHealthCheckPhone(settings.healthCheckPhone || '');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to load settings. Please try again.');
      setLoading(false);
    }
  };

  const handleSaveSettings = async () => {
    try {
      setLoading(true);
      await networkManager.put('/settings', {
        healthCheckFrequency,
        notificationMethod,
        healthCheckEmail,
        healthCheckPhone
      });
      setLoading(false);
      setError(null);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      setError('Failed to save settings. Please try again.');
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Account Settings</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your TimeCapsule preferences</p>
      </div>
      {error && (
        <div className="px-4 py-3 bg-red-100 text-red-700 border-l-4 border-red-500">
          {error}
        </div>
      )}
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Health Check Frequency</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <select
                value={healthCheckFrequency}
                onChange={(e) => setHealthCheckFrequency(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
              </select>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Notification Method</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <select
                value={notificationMethod}
                onChange={(e) => setNotificationMethod(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="email">Email</option>
                <option value="sms">SMS</option>
                <option value="both">Both Email and SMS</option>
              </select>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Health Check Email</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <input
                type="email"
                value={healthCheckEmail}
                onChange={(e) => setHealthCheckEmail(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Health Check Phone</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <PhoneInput
                country={'us'}
                value={healthCheckPhone}
                onChange={(phone: string, country: CountryData) => setHealthCheckPhone(phone)}
                inputClass="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                containerClass="w-full"
              />
            </dd>
          </div>
        </dl>
      </div>
      <ManageSubscription />
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          onClick={handleSaveSettings}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Settings'}
        </button>
      </div>
    </div>
  );
};

export default Settings;