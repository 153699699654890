import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { authClient } from './services/authClient';
import HomePage from './components/HomePage';
import SignUp from './components/SignUp';
import Login from './components/Login';
import PasswordReset from './components/PasswordReset';
import Subscription from './components/Subscription';
import Success from './components/Success';
import Dashboard from './components/Dashboard';
import GetStartedWizard from './components/GetStartedWizard';

//import Dashboard from './components/Dashboard'; // You'll need to create this component

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = authClient.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={user ? <Navigate to="/dashboard" /> : <SignUp />} />
        <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path='/get-started' element={ user? <GetStartedWizard /> : <HomePage />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/success" element={<Success />} />
        <Route path="/Dashboard" element={<Dashboard />} />
       {/* <Route 
          path="/dashboard" 
          element={user ? <Dashboard /> : <Navigate to="/login" />} 
        /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;