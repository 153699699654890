import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaUserPlus, FaTrash, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { networkManager } from '../services/NetworkManager';
import Dialog from './Dialog';

interface EmergencyContact {
  _id: string;
  fullName: string;
  email: string;
  phone: string;
  countryCode: string;
}

const EmergencyContacts: React.FC = () => {
  const [contacts, setContacts] = useState<EmergencyContact[]>([]);
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [editingContact, setEditingContact] = useState<EmergencyContact | null>(null);
  const [newContact, setNewContact] = useState<Omit<EmergencyContact, '_id'>>({
    fullName: '',
    email: '',
    phone: '',
    countryCode: '',
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    message: '',
    confirmLabel: '',
    onConfirm: () => {},
  });

  useEffect(() => {
    loadContacts();
  }, []);

  const loadContacts = async () => {
    try {
      const response = await networkManager.get('/emergency-contacts');
      setContacts(response);
    } catch (error) {
      console.error('Error loading emergency contacts:', error);
      // Handle error (show error message to user)
    }
  };

  const handleAddContact = async () => {
    if (contacts.length >= 2) {
      setDialogConfig({
        title: 'Maximum Contacts Reached',
        message: 'You can only have up to 2 emergency contacts.',
        confirmLabel: 'OK',
        onConfirm: () => {},
      });
      setDialogOpen(true);
      return;
    }
    
    try {
      const response = await networkManager.post('/emergency-contacts', newContact);
      setContacts([...contacts, response]);
      setIsAddingContact(false);
      setNewContact({ fullName: '', email: '', phone: '', countryCode: '' });
    } catch (error) {
      console.error('Error adding emergency contact:', error);
      setDialogConfig({
        title: 'Error',
        message: 'Failed to add emergency contact. Please try again.',
        confirmLabel: 'OK',
        onConfirm: () => {},
      });
      setDialogOpen(true);
    }
  };

  const handleDeleteContact = async (_id: string) => {
    if (contacts.length <= 1) {
      setDialogConfig({
        title: 'Cannot Delete Contact',
        message: 'You must have at least one emergency contact.',
        confirmLabel: 'OK',
        onConfirm: () => {},
      });
      setDialogOpen(true);
      return;
    }

    setDialogConfig({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this emergency contact?',
      confirmLabel: 'Delete',
      onConfirm: async () => {
        try {
          await networkManager.delete(`/emergency-contacts/${_id}`);
          setContacts(contacts.filter(contact => contact._id !== _id));
        } catch (error) {
          console.error('Error deleting emergency contact:', error);
          setDialogConfig({
            title: 'Error',
            message: 'Failed to delete emergency contact. Please try again.',
            confirmLabel: 'OK',
            onConfirm: () => {},
          });
          setDialogOpen(true);
        }
      },
    });
    setDialogOpen(true);
  };
  const handleUpdateContact = async (_id: string, updatedContact: Partial<EmergencyContact>) => {
    try {
      const response = await networkManager.put(`/emergency-contacts/${_id}`, updatedContact);
      setContacts(contacts.map(contact => contact._id === _id ? response : contact));
      setEditingContact(null);
    } catch (error) {
      console.error('Error updating emergency contact:', error);
      // Handle error (show error message to user)
    }
  };

  const handleEditClick = (contact: EmergencyContact) => {
    setEditingContact(contact);
  };

  const handleCancelEdit = () => {
    setEditingContact(null);
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Emergency Contacts</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          These contacts will be notified if you don't respond to health checks. You must have at least one and no more than two emergency contacts.
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <ul className="divide-y divide-gray-200">
          <AnimatePresence>
            {contacts.map((contact) => (
              <motion.li
                key={contact._id}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                {editingContact && editingContact._id === contact._id ? (
                  <div className="col-span-3">
                    <input
                      type="text"
                      value={editingContact.fullName}
                      onChange={(e) => setEditingContact({ ...editingContact, fullName: e.target.value })}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2"
                    />
                    <input
                      type="email"
                      value={editingContact.email}
                      onChange={(e) => setEditingContact({ ...editingContact, email: e.target.value })}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2"
                    />
                    <PhoneInput
                      country={editingContact.countryCode.toLowerCase()}
                      value={editingContact.phone}
                      onChange={(phone: string, country: CountryData) => {
                        setEditingContact({
                          ...editingContact,
                          phone: phone,
                          countryCode: country.countryCode
                        });
                      }}
                      inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      containerClass="block mb-2"
                    />
                    <div className="flex justify-end space-x-2 mt-2">
                      <button
                        onClick={handleCancelEdit}
                        className="text-gray-600 hover:text-gray-800 transition-colors"
                      >
                        <FaTimes />
                      </button>
                      <button
                        onClick={() => handleUpdateContact(editingContact._id, editingContact)}
                        className="text-green-600 hover:text-green-800 transition-colors"
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="text-sm font-medium text-gray-500">{contact.fullName}</div>
                    <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div>{contact.email}</div>
                      <div>{`+${contact.countryCode} ${contact.phone}`}</div>
                      <div className="mt-2 flex space-x-2">
                        <button
                          onClick={() => handleDeleteContact(contact._id)}
                          className="text-red-600 hover:text-red-800 transition-colors"
                        >
                          <FaTrash />
                        </button>
                        <button
                          onClick={() => handleEditClick(contact)}
                          className="text-blue-600 hover:text-blue-800 transition-colors"
                        >
                          <FaEdit />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </motion.li>
            ))}
          </AnimatePresence>
        </ul>
      </div>
      {isAddingContact && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="border-t border-gray-200 px-4 py-5 sm:p-6"
        >
          <h4 className="text-lg font-medium text-gray-900 mb-4">Add New Emergency Contact</h4>
          <div className="space-y-4">
            <input
              type="text"
              value={newContact.fullName}
              onChange={(e) => setNewContact({ ...newContact, fullName: e.target.value })}
              placeholder="Full Name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <input
              type="email"
              value={newContact.email}
              onChange={(e) => setNewContact({ ...newContact, email: e.target.value })}
              placeholder="Email"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <PhoneInput
              country={'us'}
              value={newContact.phone}
              onChange={(phone: string, country: CountryData) => {
                setNewContact({
                  ...newContact,
                  phone: phone,
                  countryCode: country.countryCode
                });
              }}
              inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              containerClass="block"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsAddingContact(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                onClick={handleAddContact}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Contact
              </button>
            </div>
          </div>
        </motion.div>
      )}
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        {contacts.length < 2 && !isAddingContact && (
          <button
            onClick={() => setIsAddingContact(true)}
            className="inline-flex items-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FaUserPlus className="mr-2" />
            Add Emergency Contact
          </button>
        )}
      </div>
      <Dialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogConfig.title}
        message={dialogConfig.message}
        confirmLabel={dialogConfig.confirmLabel}
        onConfirm={dialogConfig.onConfirm}
      />
    </div>
  );
};

export default EmergencyContacts;