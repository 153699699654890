import { auth } from '../firebase';


class NetworkManager {
  private static instance: NetworkManager;
  private baseUrl: string = process.env.REACT_APP_API_URL+'/api' || 'http://localhost:3001/api';


  private constructor() {}

  public static getInstance(): NetworkManager {
    if (!NetworkManager.instance) {
      NetworkManager.instance = new NetworkManager();
    }
    return NetworkManager.instance;
  }

  private async getHeaders(): Promise<Headers> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      headers.append('Authorization', `Bearer ${token}`);
    }
    
    return headers;
  }

  public async get(endpoint: string): Promise<any> {
    const headers = await this.getHeaders();
    const response = await fetch(`${this.baseUrl}${endpoint}`, { headers });
    return this.handleResponse(response);
  }

  public async post(endpoint: string, data: any): Promise<any> {
    const headers = await this.getHeaders();
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    return this.handleResponse(response);
  }

  public async put(endpoint: string, data: any): Promise<any> {
    const headers = await this.getHeaders();
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });
    return this.handleResponse(response);
  }

  public async delete(endpoint: string): Promise<any> {
    const headers = await this.getHeaders();
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      method: 'DELETE',
      headers,
    });
    return this.handleResponse(response);
  }

  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      let errorMessage;
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || 'An error occurred';
      } catch (e) {
        errorMessage = response.statusText;
      }
      throw new Error(errorMessage);
    }
    return response.json();
  }
}

export const networkManager = NetworkManager.getInstance();