import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { networkManager } from '../services/NetworkManager';

interface RequirementState {
  hasVideo: boolean;
  hasEmergencyContact: boolean;
  hasValidSettings: boolean;
}

interface AccountStatus {
  isActive: boolean;
  nextHealthCheck: string | null;
  subscription: {
    type?: string;
  } | null;
}

const AccountStatusVerification: React.FC<{ onTabChange: string }> = ({ onTabChange }) => {
  const [requirements, setRequirements] = useState<RequirementState>({
    hasVideo: false,
    hasEmergencyContact: false,
    hasValidSettings: false,
  });
  const [accountStatus, setAccountStatus] = useState<AccountStatus>({
    isActive: false,
    nextHealthCheck: null,
    subscription: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('onTabChange effect triggered');
    checkRequirements();
  }, [onTabChange]);

  const checkRequirements = async () => {
    console.log('checking requirements');
    try {
      setLoading(true);
      const [videos, emergencyContacts, settings, status] = await Promise.all([
        networkManager.get('/videos'),
        networkManager.get('/emergency-contacts'),
        networkManager.get('/settings'),
        networkManager.get('/account-status'),
      ]);

      setRequirements({
        hasVideo: videos.length > 0,
        hasEmergencyContact: emergencyContacts.length > 0,
        hasValidSettings: !!(settings.healthCheckEmail || settings.healthCheckPhone),
      });

      setAccountStatus(status);
      console.log('Requirements check completed:', { videos, emergencyContacts, settings, status });
    } catch (error) {
      console.error('Error checking requirements:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleActivate = async () => {
    try {
      await networkManager.post('/initiate-health-checks', {});
      checkRequirements();
    } catch (error) {
      console.error('Error activating account:', error);
    }
  };

  const handleSnooze = async () => {
    try {
      await networkManager.post('/snooze-health-check', {});
      checkRequirements();
    } catch (error) {
      console.error('Error snoozing health check:', error);
    }
  };

  const formatTimeUntilNextCheck = (nextCheckDate: string): string => {
    const now = new Date();
    const checkDate = new Date(nextCheckDate);
    const diffTime = Math.abs(checkDate.getTime() - now.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return "Today";
    if (diffDays === 1) return "Tomorrow";
    if (diffDays < 7) return `In ${diffDays} days`;
    if (diffDays < 30) return `In ${Math.ceil(diffDays / 7)} weeks`;
    return `In ${Math.ceil(diffDays / 30)} months`;
  };

  const canSnooze = (nextCheckDate: string): boolean => {
    const now = new Date();
    const checkDate = new Date(nextCheckDate);
    const diffTime = Math.abs(checkDate.getTime() - now.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 30;
  };

  if (loading) {
    return <div className="text-center">Checking account status...</div>;
  }

  const canActivate = requirements.hasVideo && 
                      requirements.hasEmergencyContact && 
                      requirements.hasValidSettings && 
                      accountStatus.subscription !== null;

  const openTasks = [
    accountStatus.subscription === null && (
      <span>
        Subscribe to a valid plan - <Link to="/subscription" className="text-indigo-600 hover:text-indigo-800">Go to Subscription</Link>
      </span>
    ),
    !requirements.hasVideo && 'Upload at least one video',
    !requirements.hasEmergencyContact && 'Add at least one emergency contact',
    !requirements.hasValidSettings && 'Set up notification preferences in Settings',
  ].filter(Boolean);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white shadow overflow-hidden sm:rounded-lg mb-6"
      >
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Account Status</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            {accountStatus.isActive ? (
              <p>
                Active - Next check:{' '}
                {accountStatus.nextHealthCheck && (
                  <span title={new Date(accountStatus.nextHealthCheck).toLocaleString()}>
                    {formatTimeUntilNextCheck(accountStatus.nextHealthCheck)}
                  </span>
                )}
              </p>
            ) : (
              <div>
                {canActivate
                  ? "Your account is ready to be activated. Click the button below to start health checks."
                  : "Your account is not active. Please complete all requirements to activate."}
              </div>
            )}
          </div>
          {!accountStatus.isActive && openTasks.length > 0 && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-700">Open tasks:</p>
              <ul className="mt-2 text-sm text-gray-600 list-disc list-inside">
                {openTasks.map((task, index) => (
                  <li key={index}>{task}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="mt-5">
            {accountStatus.isActive ? (
              accountStatus.nextHealthCheck && canSnooze(accountStatus.nextHealthCheck) ? (
                <button
                  type="button"
                  onClick={handleSnooze}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Snooze 1 Month
                </button>
              ) : (
                <p className="text-sm text-gray-500">Snoozing unavailable</p>
              )
            ) : (
              <button
                type="button"
                onClick={handleActivate}
                disabled={!canActivate}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                  canActivate
                    ? 'bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                    : 'bg-gray-300 cursor-not-allowed'
                }`}
              >
                Activate Account
              </button>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default AccountStatusVerification;
