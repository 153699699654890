import React, { useRef, useEffect, useState } from 'react';
import { useReactMediaRecorder } from "react-media-recorder";
import { motion, AnimatePresence } from 'framer-motion';
import { FaVideo, FaTimes } from 'react-icons/fa';
import { RecordingMode } from './types';
import Timer from '../Timer';

interface VideoRecorderProps {
  onRecordingComplete: (blobUrl: string) => void;
  setMode: (mode: RecordingMode) => void;
  mode: RecordingMode;
  maxDuration: number;
}

const VideoRecorder: React.FC<VideoRecorderProps> = ({ onRecordingComplete, setMode, mode, maxDuration }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    previewStream
  } = useReactMediaRecorder({
    video: true,
    audio: true,
    blobPropertyBag: { type: "video/mp4"}
  });

  useEffect(() => {
    if (videoRef.current && previewStream) {
      videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  useEffect(() => {
    if (status === 'stopped' && mediaBlobUrl) {
      onRecordingComplete(mediaBlobUrl);
      setMode('preview');
      setIsModalOpen(false);
      stopPreviewStream();
    }
  }, [status, mediaBlobUrl, onRecordingComplete, setMode]);

  const stopPreviewStream = () => {
    if (previewStream) {
      const tracks = (previewStream as MediaStream).getTracks();
      tracks.forEach(track => track.stop());
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMode('prepare');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setMode('choose');
    clearBlobUrl();
    stopPreviewStream();
  };

  const handleStartCountdown = () => {
    setMode('countdown');
  };

  const handleCountdownEnd = () => {
    setMode('record');
    startRecording();
  };

  const handleStopRecording = () => {
    stopRecording();
    stopPreviewStream();
  };

  const getTimerMode = () => {
    if (mode === 'countdown') return 'countdown';
    if (mode === 'record') return 'recording';
    return 'idle';
  };

  return (
    <>
      {mode !== 'preview' && (
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleOpenModal}
          className="w-full flex items-center justify-center p-4 bg-green-500 text-white rounded-lg transition-colors hover:bg-green-600 h-14 text-lg font-semibold"
        >
          <FaVideo className="mr-2 text-2xl" />
          <span className="font-medium">Record Video</span>
        </motion.button>
      )}

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div className="bg-white rounded-lg p-6 w-full max-w-3xl">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Record Video</h2>
                <button onClick={handleCloseModal} className="text-gray-500 hover:text-gray-700">
                  <FaTimes size={24} />
                </button>
              </div>

              <div className="relative w-full h-0 pb-[56.25%] bg-gray-100 rounded-lg overflow-hidden mb-4">
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  muted
                  className="absolute top-0 left-0 w-full h-full object-cover"
                />
                <Timer
                  mode={getTimerMode()}
                  maxDuration={maxDuration}
                  onCountdownEnd={handleCountdownEnd}
                  onMaxDurationReached={handleStopRecording}
                />
              </div>

              <div className="flex justify-center space-x-4">
                {mode === 'prepare' && (
                  <button
                    onClick={handleStartCountdown}
                    className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300 h-12 w-48 text-lg font-semibold"
                  >
                    Start Recording
                  </button>
                )}
                {mode === 'record' && (
                  <button
                    onClick={handleStopRecording}
                    className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300 h-12 w-48 text-lg font-semibold"
                  >
                    Stop Recording
                  </button>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default VideoRecorder;
