// src/components/VideoManagement/VideoList.tsx

import React from 'react';
import VideoItem from './VideoItem';
import { Video } from './types';

interface VideoListProps {
  videos: Video[];
  onDeleteVideo: (id: string, url: string) => void;
  onUpdateContactInfo: (id: string, updatedContact: { email: string, phone: string }) => void;
}

const VideoList: React.FC<VideoListProps> = ({ videos, onDeleteVideo, onUpdateContactInfo }) => {
  return (
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-xl font-semibold text-gray-900 mb-4">Your Videos</h3>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {videos.map((video) => (
          <VideoItem
            key={video.id}
            video={video}
            onDelete={onDeleteVideo}
            onUpdateContact={onUpdateContactInfo}
          />
        ))}
      </ul>
    </div>
  );
};

export default VideoList;