import React, { useState, useEffect, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AnimatePresence, motion } from 'framer-motion';
import { auth, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { networkManager } from '../services/NetworkManager';
import VideoRecorder from './VideoManagement/VideoRecorder';
import VideoUploader from './VideoManagement/VideoUploader';
import VideoList from './VideoManagement/VideoList';
import { Video, Subscription, RecordingMode } from './VideoManagement/types';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { ClipLoader } from 'react-spinners';
import Dialog from './Dialog';

const VideoManagement: React.FC = () => {
  const [user] = useAuthState(auth);
  const [videos, setVideos] = useState<Video[]>([]);
  const [mode, setMode] = useState<RecordingMode>('choose');
  const [file, setFile] = useState<File | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const [videoTitle, setVideoTitle] = useState('');
  const [contactInfo, setContactInfo] = useState({ fullName: '', email: '', phone: '', countryCode: '' });
  const [isUploading, setIsUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    message: '',
    confirmLabel: '',
    onConfirm: () => {},
  });

  useEffect(() => {
    if (user) {
      loadVideos();
      loadSubscription();
    }
  }, [user]);

  const loadSubscription = async () => {
    if (!user) return;
    try {
      const response = await networkManager.get('/settings');
      if (response.subscriptionPlan) {
        setSubscription(response.subscriptionPlan);
      }
    } catch (error) {
      console.error('Error loading subscription:', error);
    }
  };

  const loadVideos = async () => {
    if (!user) return;
    try {
      const response = await networkManager.get('/videos');
      const videosWithIds = response.map((video: any) => ({
        ...video,
        id: video.id || video._id,
      }));
      setVideos(videosWithIds);
    } catch (error) {
      console.error('Error loading videos:', error);
    }
  };

  const handleUpload = async (title: string, contactInfo: any) => {
    if (!user || (!mediaBlobUrl && !file) || !subscription) return;

    if (videos.length >= subscription.maxVideos) {
      setDialogConfig({
        title: 'Maximum Videos Reached',
        message: `You've reached the maximum number of videos for your ${subscription.name} plan.`,
        confirmLabel: 'OK',
        onConfirm: () => {},
      });
      setDialogOpen(true);
      return;
    }

    setIsUploading(true);

    try {
      let downloadURL = '';
      let duration = 0;

      if (file) {
       // duration = await getVideoDuration(file);
      } else if (mediaBlobUrl) {
        const response = await fetch(mediaBlobUrl);
        const blob = await response.blob();
        //duration = await getVideoDuration(blob);
      }

      console.log("Video duration:", duration);

      /*if (duration > subscription.maxDuration) {
        setDialogConfig({
          title: 'Video Too Long',
          message: `The video duration (${Math.round(duration)} seconds) exceeds the maximum allowed duration (${subscription.maxDuration} seconds) for your plan.`,
          confirmLabel: 'OK',
          onConfirm: () => {},
        });
        setDialogOpen(true);
        setIsUploading(false);
        return;
      }*/

      if (file) {
        const videoRef = ref(storage, `videos/${user.uid}/${Date.now()}_${file.name}`);
        const uploadResult = await uploadBytes(videoRef, file);
        downloadURL = await getDownloadURL(uploadResult.ref);
      } else if (mediaBlobUrl) {
        const response = await fetch(mediaBlobUrl);
        const blob = await response.blob();
        const videoRef = ref(storage, `videos/${user.uid}/${Date.now()}.webm`);
        const uploadResult = await uploadBytes(videoRef, blob);
        downloadURL = await getDownloadURL(uploadResult.ref);
      }

      await saveVideo(downloadURL, duration, title, contactInfo);
    } catch (error) {
      console.error('Error uploading video:', error);
      setDialogConfig({
        title: 'Upload Error',
        message: 'Failed to upload video. Please try again.',
        confirmLabel: 'OK',
        onConfirm: () => {},
      });
      setDialogOpen(true);
    } finally {
      setIsUploading(false);
    }
  };

  const getVideoDuration = (file: File | Blob): Promise<number> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        if (isFinite(video.duration)) {
          resolve(video.duration);
        } else {
          reject("Unable to determine video duration");
        }
      }

      video.onerror = function() {
        reject("Invalid video. Please select another video file.");
      }

      video.src = URL.createObjectURL(file);
    });
  }

  const saveVideo = async (downloadURL: string, duration: number, title: string, contactInfo: any) => {
    const videoData = {
      title,
      url: downloadURL,
      duration,
      contact: contactInfo,
    };
    const savedVideo = await networkManager.post('/videos', videoData);

    setVideos([...videos, { ...savedVideo, id: savedVideo.id || savedVideo._id }]);

    setFile(null);
    setMediaBlobUrl(null);
    setMode('choose');
    setVideoTitle('');
    setContactInfo({ fullName: '', email: '', phone: '', countryCode: '' });
  };

  const handleUpdateContactInfo = async (id: string, updatedContact: { email: string; phone: string }) => {
    try {
      const response = await networkManager.put(`/videos/${id}/contact`, updatedContact);
      setVideos(videos.map((video) => (video.id === id ? { ...video, contact: response.data.contact } : video)));
    } catch (error) {
      console.error('Error updating contact info:', error);
    }
  };

  const handleDeleteVideo = async (id: string, url: string) => {
    if (!id) {
      console.error('Invalid video ID');
      return;
    }

    setDialogConfig({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this video?',
      confirmLabel: 'Delete',
      onConfirm: async () => {
        try {
          const videoRef = ref(storage, url);
          try {
            await deleteObject(videoRef);
          } catch (storageError) {
            console.warn('Error deleting from storage, proceeding with backend deletion:', storageError);
          }

          await networkManager.delete(`/videos/${id}`);

          setVideos(videos.filter((video) => video.id !== id));
        } catch (error) {
          console.error('Error deleting video:', error);
          setDialogConfig({
            title: 'Deletion Error',
            message: 'Failed to delete video. Please try again.',
            confirmLabel: 'OK',
            onConfirm: () => {},
          });
          setDialogOpen(true);
        }
      },
    });
    setDialogOpen(true);
  };

  const handleRecordingComplete = useCallback((blobUrl: string) => {
    setMediaBlobUrl(blobUrl);
    setMode('preview');
  }, []);

  const handleDeleteRecording = useCallback(() => {
    setMediaBlobUrl(null);
    setMode('choose');
    setVideoTitle('');
    setContactInfo({ fullName: '', email: '', phone: '', countryCode: '' });
  }, []);

  const handleUseRecording = useCallback(() => {
    setMode('upload');
  }, []);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-2xl leading-6 font-bold text-gray-900">Video Management</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Record and manage your TimeCapsule videos</p>
        {subscription && (
          <div className="mt-2 text-sm text-gray-600">
            Your {subscription.name} plan allows {subscription.maxVideos} videos, up to {subscription.maxDuration} seconds each.
            You have used {videos.length} out of {subscription.maxVideos} videos.
          </div>
        )}
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <AnimatePresence mode="wait">
          <div className="flex justify-center items-start">
            {mode !== 'upload' && (
              <div className="flex justify-center items-center space-x-4 my-6">
                <VideoRecorder 
                  onRecordingComplete={handleRecordingComplete} 
                  setMode={setMode} 
                  mode={mode} 
                  maxDuration={subscription ? subscription.maxDuration : 60}
                />
                <VideoUploader 
                  onFileSelect={(selectedFile) => setFile(selectedFile)} 
                  onUpload={handleUpload} 
                  setMode={setMode} 
                  mode={mode} 
                />
              </div>
            )}
            {mode === 'preview' && mediaBlobUrl && (
              <motion.div
                key="preview"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="p-4 flex flex-col items-center"
              >
                <video src={mediaBlobUrl} controls className="w-full max-w-md rounded-lg shadow-lg" />
                <div className="mt-4 flex justify-center space-x-4">
                  <button
                    onClick={handleDeleteRecording}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
                  >
                    Delete
                  </button>
                  <button
                    onClick={handleUseRecording}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
                  >
                    Use This Video
                  </button>
                </div>
              </motion.div>
            )}
            {mode === 'upload' && (
              <motion.div
                key="upload"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="p-4 max-w-md mx-auto"
              >
                <input
                  type="text"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  placeholder="Enter video title"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <input
                  type="text"
                  value={contactInfo.fullName}
                  onChange={(e) => setContactInfo({ ...contactInfo, fullName: e.target.value })}
                  placeholder="Contact Full Name"
                  className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <input
                  type="email"
                  value={contactInfo.email}
                  onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                  placeholder="Contact Email"
                  className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <PhoneInput
                  country={'us'}
                  value={contactInfo.phone}
                  onChange={(phone: string, country: CountryData) => {
                    setContactInfo({
                      ...contactInfo,
                      phone: phone,
                      countryCode: country.countryCode,
                    });
                  }}
                  inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  containerClass="block"
                />
                <div className="mt-4 flex justify-center space-x-4">
                  <button
                    onClick={() => setMode('choose')}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleUpload(videoTitle, contactInfo)}
                    className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 transition duration-300"
                    disabled={isUploading}
                  >
                    {isUploading ? <ClipLoader size={20} color={'#fff'} /> : 'Upload Video'}
                  </button>
                </div>
              </motion.div>
            )}
          </div>
        </AnimatePresence>
      </div>
      <VideoList videos={videos} onDeleteVideo={handleDeleteVideo} onUpdateContactInfo={handleUpdateContactInfo} />
      <Dialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogConfig.title}
        message={dialogConfig.message}
        confirmLabel={dialogConfig.confirmLabel}
        onConfirm={dialogConfig.onConfirm}
      />
    </div>
  );
};

export default VideoManagement;
