import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    updateProfile,
    User,
    UserCredential,
    onAuthStateChanged
  } from 'firebase/auth';
  import { auth } from '../firebase';
  
  class AuthClient {
    private user: User | null = null;
  
    constructor() {
      // Initialize the user state when the AuthClient is instantiated
      onAuthStateChanged(auth, (user) => {
        this.user = user;
      });
    }
  
    async signUp(email: string, password: string, displayName: string): Promise<User> {
      try {
        const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(userCredential.user, { displayName });
        this.user = userCredential.user;
        return this.user;
      } catch (error) {
        console.error('Error signing up:', error);
        throw error;
      }
    }
  
    async signIn(email: string, password: string): Promise<User> {
      try {
        const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
        this.user = userCredential.user;
        return this.user;
      } catch (error) {
        console.error('Error signing in:', error);
        throw error;
      }
    }
  
    async signOut(): Promise<void> {
      try {
        await signOut(auth);
        this.user = null;
      } catch (error) {
        console.error('Error signing out:', error);
        throw error;
      }
    }
  
    async resetPassword(email: string): Promise<void> {
      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        console.error('Error resetting password:', error);
        throw error;
      }
    }
  
    async getToken(): Promise<string | null> {
      if (this.user) {
        return await this.user.getIdToken(true);  // Force token refresh
      }
      return null;
    }
  
    getCurrentUser(): User | null {
      return this.user || auth.currentUser;
    }
  
    async refreshUser(): Promise<void> {
      if (auth.currentUser) {
        this.user = auth.currentUser;
        await this.user.reload();
      }
    }
  
    onAuthStateChanged(callback: (user: User | null) => void): () => void {
      return onAuthStateChanged(auth, (user) => {
        this.user = user;
        callback(user);
      });
    }
  }
  
  export const authClient = new AuthClient();