import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { networkManager } from '../services/NetworkManager';
import { authClient } from '../services/authClient';
import { FaUser, FaUserFriends, FaCreditCard, FaCheck, FaHeartbeat, FaUserShield, FaEnvelope } from 'react-icons/fa';


// Import the Subscription component
import Subscription from './Subscription';


const steps = [
    { 
      icon: FaHeartbeat, 
      title: 'Health Check Settings',
      description: 'Set up your health check frequency and notification method. We\'ll use this to ensure you\'re okay.'
    },
    { 
      icon: FaUserShield, 
      title: 'Emergency Contacts',
      description: 'Add trusted individuals who can confirm your status if you don\'t respond to health checks.'
    },
    { 
      icon: FaEnvelope, 
      title: 'Content Distribution',
      description: 'Choose your subscription and set up how your content will be distributed if confirmed inactive.'
    },
  ];

const GetStartedWizard: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [userData, setUserData] = useState({
    email: '',
    phone: '',
    healthCheckFrequency: 'weekly',
    notificationMethod: 'email',
  });
  const [emergencyContacts, setEmergencyContacts] = useState([
    { fullName: '', email: '', phone: '', countryCode: '' },
  ]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const user = authClient.getCurrentUser();
    if (user?.email) {
      setUserData(prevData => ({ ...prevData, email: user.email || '' }));
    }
  }, []);

  const handleSkipForNow = () => {
    navigate('/dashboard');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value: string) => {
    setUserData(prevData => ({ ...prevData, phone: value }));
  };

  const handleEmergencyContactChange = (index: number, field: string, value: string) => {
    const updatedContacts = [...emergencyContacts];
    updatedContacts[index] = { ...updatedContacts[index], [field]: value };
    setEmergencyContacts(updatedContacts);
  };



  const handleAddEmergencyContact = () => {
    if (emergencyContacts.length < 2) {
      setEmergencyContacts([...emergencyContacts, { fullName: '', email: '', phone: '', countryCode: '' }]);
    }
  };

  const handleRemoveEmergencyContact = (index: number) => {
    const updatedContacts = emergencyContacts.filter((_, i) => i !== index);
    setEmergencyContacts(updatedContacts);
  };

  const isCountryData = (country: {} | CountryData): country is CountryData => {
    return (country as CountryData).countryCode !== undefined;
  };

  const handleEmergencyContactPhoneChange = (index: number, phone: string, country: {} | CountryData) => {
    const updatedContacts = [...emergencyContacts];
    updatedContacts[index] = { 
      ...updatedContacts[index], 
      phone: phone,
      countryCode: isCountryData(country) ? country.countryCode : ''
    };
    setEmergencyContacts(updatedContacts);
  };

  const validateData = () => {
    if (currentStep === 0) {
      if (!userData.email || !userData.phone) {
        return 'Please provide both email and phone number.';
      }
    } else if (currentStep === 1) {
      for (const contact of emergencyContacts) {
        if (!contact.fullName || !contact.email || !contact.phone) {
          return 'Please fill in all fields for emergency contacts.';
        }
      }
    }
    return null;
  };

  const handleNextStep = async () => {
    const validationError = validateData();
    if (validationError) {
      setError(validationError);
      return;
    }

    setError(null);

    if (currentStep === 0) {
      try {
        await networkManager.put('/settings', userData);
      } catch (error) {
        console.error('Error saving user data:', error);
        setError('Error saving user data. Please try again.');
        return;
      }
    } else if (currentStep === 1) {
      try {
        for (const contact of emergencyContacts) {
          await networkManager.post('/emergency-contacts', contact);
        }
      } catch (error) {
        console.error('Error saving emergency contacts:', error);
        setError('Error saving emergency contacts. Please try again.');
        return;
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      navigate('/dashboard');
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h2 className="text-2xl font-bold mb-4">Health Check Settings</h2>
            <p className="mb-4 text-gray-600">
              <FaHeartbeat className="inline-block mr-2" />
              We'll periodically check on you to ensure you're okay. If you don't respond, we'll contact your emergency contacts.
            </p>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                <PhoneInput
                    country={'us'}
                    value={userData.phone}
                    onChange={handlePhoneChange}
                    inputClass="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    containerClass="w-full"
                    />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Health Check Frequency</label>
                <select
                  name="healthCheckFrequency"
                  value={userData.healthCheckFrequency}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Notification Method</label>
                <select
                  name="notificationMethod"
                  value={userData.notificationMethod}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="email">Email</option>
                  <option value="sms">SMS</option>
                  <option value="both">Both Email and SMS</option>
                </select>
              </div>
            </div>
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h2 className="text-2xl font-bold mb-4">Emergency Contacts</h2>
            <p className="mb-4 text-gray-600">
              <FaUserShield className="inline-block mr-2" />
              These trusted individuals will be contacted to confirm your status if you don't respond to health checks.
              If they confirm you're inactive, your content will be distributed as specified in the next step.
            </p>
            {emergencyContacts.map((contact, index) => (
              <div key={index} className="mb-6 p-4 border border-gray-200 rounded-md">
                <h3 className="text-lg font-semibold mb-2">Contact {index + 1}</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Full Name</label>
                    <input
                      type="text"
                      value={contact.fullName}
                      onChange={(e) => handleEmergencyContactChange(index, 'fullName', e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                      type="email"
                      value={contact.email}
                      onChange={(e) => handleEmergencyContactChange(index, 'email', e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <PhoneInput
                        country={'us'}
                        value={contact.phone}
                        onChange={(phone, country) => handleEmergencyContactPhoneChange(index, phone, country)}
                        inputClass="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        containerClass="w-full"
                        />
                  </div>
                </div>
                {emergencyContacts.length > 1 && (
                  <button
                    onClick={() => handleRemoveEmergencyContact(index)}
                    className="mt-2 text-red-600 hover:text-red-800"
                  >
                    Remove Contact
                  </button>
                )}
              </div>
            ))}
            {emergencyContacts.length < 2 && (
              <button
                onClick={handleAddEmergencyContact}
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Another Contact
              </button>
            )}
          </motion.div>
        );
        case 2:
            return (
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
              >
                <h2 className="text-2xl font-bold mb-4">Content Distribution</h2>
                <p className="mb-4 text-gray-600">
                  <FaEnvelope className="inline-block mr-2" />
                  Choose your subscription plan and set up how your content will be distributed if you're confirmed inactive.
                </p>
                <Subscription />
              </motion.div>
            );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Get Started with TimeCapsule</h1>
            {currentStep === 3 && (
                <button
                  onClick={handleSkipForNow}
                  className="text-sm text-indigo-600 hover:text-indigo-500"
                >
                  Skip for now
                </button>
              )}
            <div className="mb-8">
              <div className="flex items-center justify-between">
                {steps.map((step, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center ${
                        index <= currentStep ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-400'
                      }`}
                    >
                      {index < currentStep ? <FaCheck /> : <step.icon />}
                    </div>
                    <div className="mt-2 text-sm font-medium text-gray-500">{step.title}</div>
                  </div>
                ))}
              </div>
              <div className="mt-4 relative">
                <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-full h-1 bg-gray-200"></div>
                <div
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 h-1 bg-indigo-600 transition-all duration-300 ease-in-out"
                  style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
                ></div>
              </div>
            </div>
            {error && (
              <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                {error}
              </div>
            )}
            <AnimatePresence mode="wait">
              {renderStep()}
            </AnimatePresence>
            <div className="mt-8 flex justify-between">
              {currentStep > 0 && (
                <button
                  onClick={handlePrevStep}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Previous
                </button>
              )}
              <button
                onClick={handleNextStep}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedWizard